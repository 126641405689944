import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 720.000000 971.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,971.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 4855 l0 -4855 3600 0 3600 0 0 4855 0 4855 -3600 0 -3600 0 0
-4855z m4002 2011 c186 -26 381 -78 573 -151 39 -15 75 -30 80 -34 6 -4 29
-15 52 -24 143 -56 469 -271 613 -406 448 -418 704 -840 819 -1351 34 -147 41
-191 56 -335 39 -366 -2 -769 -111 -1100 -40 -122 -108 -286 -152 -367 -64
-116 -85 -154 -97 -168 -6 -8 -23 -35 -38 -60 -14 -25 -35 -56 -46 -70 -11
-14 -36 -47 -57 -75 -84 -113 -105 -136 -239 -270 -250 -250 -483 -418 -755
-543 -52 -24 -104 -46 -115 -49 -11 -3 -24 -9 -30 -13 -17 -12 -195 -67 -300
-93 -521 -128 -1101 -95 -1585 90 -99 38 -371 173 -390 193 -3 3 -30 21 -60
40 -30 19 -59 38 -65 42 -5 5 -46 35 -90 69 -201 152 -394 344 -538 535 -120
160 -164 232 -252 409 -168 338 -244 650 -261 1072 -6 154 12 418 35 526 5 23
15 74 21 112 29 166 118 426 204 595 68 133 208 360 248 401 7 8 35 42 61 77
111 145 361 383 521 496 297 209 551 327 881 406 103 25 174 36 395 64 78 9
519 -3 622 -18z"/>
<path d="M3435 6643 c-16 -2 -88 -10 -160 -19 -209 -25 -451 -91 -617 -169
-42 -19 -78 -35 -81 -35 -19 0 -331 -192 -357 -220 -3 -3 -25 -21 -50 -40 -81
-63 -94 -74 -184 -160 -161 -154 -330 -364 -426 -530 -41 -72 -150 -297 -150
-310 0 -6 -7 -24 -15 -39 -33 -65 -98 -325 -122 -491 -25 -175 -25 -529 0
-695 40 -266 97 -452 208 -681 66 -136 195 -342 258 -413 17 -19 31 -38 31
-42 0 -12 301 -313 345 -345 22 -16 42 -32 45 -35 16 -18 199 -143 281 -191
86 -50 136 -75 264 -133 54 -25 233 -83 325 -106 242 -61 623 -80 895 -46 425
54 771 198 1128 470 75 56 294 267 368 352 172 200 338 487 422 732 167 482
167 1083 0 1573 -44 131 -201 455 -232 480 -3 3 -19 25 -35 50 -75 116 -155
211 -310 365 -147 146 -343 305 -376 305 -5 0 -10 4 -10 8 0 17 -247 149 -395
212 -61 26 -216 72 -330 99 -197 46 -565 74 -720 54z m208 -290 c-24 -2 -62
-2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z m-278 -14 c-44 -5 -99 -13 -122
-18 -24 -4 -43 -5 -43 0 0 4 21 11 48 14 26 3 54 7 62 8 8 2 42 3 75 4 56 0
54 0 -20 -8z m458 4 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z
m87 -3 c12 -8 12 -10 -5 -10 -11 0 -27 5 -35 10 -12 8 -12 10 5 10 11 0 27 -5
35 -10z m155 -20 c18 -8 18 -9 -5 -9 -14 0 -32 4 -40 9 -12 8 -12 10 5 9 11 0
29 -4 40 -9z m-915 -15 c-19 -7 -47 -14 -62 -14 -15 -1 -29 -5 -32 -10 -4 -5
-30 -14 -59 -20 -62 -13 -132 -40 -245 -92 -46 -22 -85 -39 -88 -39 -8 0 -248
-151 -264 -167 -8 -7 -44 -37 -80 -64 -95 -75 -222 -200 -320 -315 -39 -46
-147 -198 -167 -235 -10 -19 -28 -48 -40 -66 -13 -17 -23 -38 -23 -46 0 -9 -5
-19 -10 -22 -11 -7 -19 -25 -82 -179 -44 -108 -67 -182 -73 -236 -2 -20 -11
-51 -20 -70 -15 -35 -15 -35 -9 30 4 36 11 68 17 72 6 3 11 30 12 60 1 38 5
52 14 50 9 -2 12 5 9 23 -3 14 1 30 8 36 8 6 14 20 14 30 0 9 7 24 15 33 8 8
15 24 15 35 0 11 5 23 11 26 6 4 8 22 4 46 -4 24 -3 39 3 39 6 0 14 10 18 23
8 27 34 58 44 52 4 -2 6 13 5 34 -2 29 4 46 21 66 12 15 26 40 29 56 4 16 13
29 21 29 8 0 14 5 14 11 0 6 11 24 25 41 14 16 25 36 25 44 0 9 11 14 33 15
29 0 30 1 10 9 -13 5 -23 16 -23 24 0 10 6 13 15 9 17 -6 84 61 111 110 8 15
22 27 30 27 11 0 14 6 10 16 -4 12 -1 14 14 9 15 -5 18 -3 14 9 -4 11 3 20 23
28 35 16 46 30 30 41 -7 5 -3 6 11 2 16 -5 22 -2 22 10 0 8 8 19 18 22 9 4 23
13 31 21 12 12 12 15 0 24 -11 8 -11 9 3 4 10 -3 21 1 25 8 9 14 50 39 71 42
7 1 27 17 44 34 16 17 43 36 59 42 16 5 29 14 29 19 0 5 9 9 20 9 11 0 20 3
20 8 0 8 91 52 108 52 6 0 13 -6 16 -12 3 -7 3 -3 0 9 -4 19 1 24 46 37 28 8
78 20 110 27 60 12 86 35 31 27 -17 -3 -27 0 -24 5 3 5 23 7 45 4 36 -5 95 7
108 22 3 3 16 7 30 9 76 9 106 15 112 21 5 4 25 8 45 8 l38 2 -35 -14z m995
-5 c7 0 16 -8 20 -17 5 -15 4 -16 -6 -5 -7 6 -21 12 -30 12 -10 0 -21 5 -25
12 -5 8 -2 9 10 5 10 -4 24 -7 31 -7z m142 -46 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m73 -4 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m37 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m88 -34 c-3 -4 -15 -6 -27 -2 -21 5 -21 6 -2 12 21 8 37 3 29
-10z m75 -40 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m140 -80 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m91 -56 c7 -8 9 -17 5 -21 -3 -3 -6 -1 -6 5 0 7 -6 12 -14 12
-7 0 -19 6 -25 14 -10 12 -8 13 8 9 11 -3 26 -12 32 -19z m124 -94 c3 -5 2
-10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m166 -152
c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z m-387 -277 c3 -5
22 -9 42 -8 21 1 39 -5 44 -13 11 -18 5 -25 -12 -14 -7 5 -31 9 -53 10 -22 1
-30 -1 -17 -3 54 -11 14 -21 -85 -22 -73 0 -103 2 -93 9 8 5 25 10 38 10 12 0
22 5 22 10 0 6 21 11 48 12 47 1 47 1 -13 8 l-60 7 67 1 c37 1 69 -2 72 -7z
m-147 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-54 -54 c-3
-3 -8 1 -10 8 -3 6 -17 12 -31 13 l-27 1 25 7 c17 5 29 2 37 -8 6 -9 9 -18 6
-21z m262 10 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4
16 -10z m813 -20 c7 -11 9 -20 4 -20 -4 0 -13 9 -20 20 -7 11 -9 20 -4 20 4 0
13 -9 20 -20z m-3808 -85 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8
23 -8z m3900 -89 c0 -5 -7 -1 -15 10 -8 10 -14 24 -14 29 0 6 6 1 14 -9 8 -11
15 -24 15 -30z m27 -68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m23 -58 c0 -13 -1 -13 -10 0 -5 8 -10 22 -10 30 0 13 1 13 10 0 5 -8 10 -22
10 -30z m-4073 -62 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m4123 -57 c0 -3 -7 4 -15 15 -8 10 -14 30 -14 44 0 20 3 17 14 -15 8 -22 15
-42 15 -44z m-40 50 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6
0 10 -4 10 -9z m-2626 -12 c26 -1 24 -3 -14 -10 -100 -19 -146 -67 -165 -174
-10 -56 -13 -60 -40 -63 -16 -2 -48 -1 -72 3 -40 6 -43 9 -43 36 0 121 143
232 274 213 16 -2 43 -4 60 -5z m2703 -209 c-3 -21 -4 -21 -6 6 0 16 -5 34
-11 40 -5 5 -12 32 -15 59 l-5 50 20 -65 c11 -36 19 -76 17 -90z m-2489 53
c21 -28 14 -35 -30 -28 -51 9 -57 13 -38 25 11 7 8 10 -12 10 -16 0 -28 -4
-28 -9 0 -5 -20 -11 -45 -13 -36 -2 -44 0 -42 12 3 23 26 28 107 24 54 -4 79
-10 88 -21z m-224 -17 c-21 -15 -30 -14 -19 3 3 6 14 11 23 11 13 0 13 -2 -4
-14z m305 -2 c24 -9 43 -25 47 -37 4 -12 12 -26 18 -30 18 -14 -37 4 -62 19
-12 8 -20 19 -17 24 4 6 2 10 -2 10 -14 0 -49 29 -36 30 7 0 31 -7 52 -16z
m130 -66 c31 -16 40 -26 35 -40 -3 -10 0 -18 6 -18 6 0 8 5 5 10 -3 6 1 10 9
10 9 0 16 -4 16 -10 0 -5 3 -9 8 -8 4 1 25 -6 47 -16 25 -11 53 -15 73 -12 57
9 184 -11 231 -36 85 -45 111 -110 111 -280 0 -79 3 -94 29 -139 33 -58 32
-88 -3 -82 -21 4 -23 0 -33 -71 -13 -93 -36 -126 -110 -163 -55 -27 -63 -28
-250 -31 -204 -5 -218 -9 -166 -47 15 -11 45 -34 67 -52 150 -118 300 -201
439 -244 171 -53 288 -33 348 58 11 15 19 39 20 53 l0 25 10 -25 c13 -32 2
-124 -21 -175 -34 -75 -89 -112 -186 -126 -63 -9 -199 8 -249 31 -11 5 -56 23
-100 41 -81 33 -312 172 -365 221 -17 15 -38 33 -49 40 -54 38 -167 144 -256
242 -29 31 -133 156 -151 181 -16 23 -29 30 -60 31 -61 3 -64 -4 -64 -137 l0
-119 -74 0 c-59 0 -75 3 -80 16 -10 26 -6 773 4 779 5 3 98 6 208 7 193 1 200
0 254 -24 34 -16 66 -40 84 -63 29 -38 29 -39 29 -179 0 -160 -10 -194 -70
-235 -19 -13 -35 -28 -35 -34 0 -20 160 -195 208 -227 48 -32 47 -38 50 369 2
371 3 385 22 398 22 15 28 39 8 27 -8 -5 -7 -1 2 10 13 16 12 18 -5 16 -33 -4
-38 -1 -25 15 10 12 10 15 -3 15 -9 0 -19 5 -22 10 -10 16 10 12 54 -12z
m2304 -63 c4 -27 3 -35 -3 -25 -5 8 -10 28 -10 44 0 39 6 30 13 -19z m-1481
-182 l3 -158 138 -3 137 -3 1 146 c1 80 2 151 3 158 1 8 25 13 77 15 67 2 77
0 81 -15 3 -13 2 -641 -2 -764 0 -14 -12 -17 -62 -18 -104 -1 -98 -11 -97 159
1 81 -1 153 -5 158 -7 11 -245 11 -263 -1 -10 -6 -13 -48 -13 -153 0 -131 -2
-144 -18 -144 -9 0 -34 -9 -55 -20 -35 -19 -39 -19 -62 -4 -19 13 -25 25 -25
54 0 21 -7 57 -15 80 -8 24 -13 52 -9 62 3 11 0 20 -10 24 -21 8 -20 24 2 24
15 1 15 2 -3 16 -14 11 -15 15 -5 14 41 -6 40 -9 40 265 l0 265 80 0 79 0 3
-157z m-2775 105 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m4292
-118 c1 -20 -1 -22 -9 -10 -5 8 -9 40 -9 70 l2 55 8 -45 c4 -25 8 -56 8 -70z
m-4304 3 c-10 -40 -17 -20 -9 23 5 24 9 32 12 21 2 -10 1 -30 -3 -44z m4112
-35 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-4124 -200 c-2
-111 -5 -204 -8 -206 -7 -8 -6 340 1 377 4 17 7 31 8 31 1 0 0 -91 -1 -202z
m4314 140 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m12 -157
c1 -82 -6 -57 -12 44 -3 61 -2 77 3 50 5 -22 9 -64 9 -94z m-24 -363 c-29
-159 -37 -194 -65 -293 -125 -442 -423 -862 -837 -1180 -40 -30 -75 -55 -77
-55 -2 0 -34 -21 -72 -46 -95 -64 -329 -177 -443 -213 -227 -74 -438 -113
-591 -109 l-65 1 80 8 c171 17 190 20 212 34 12 8 26 15 30 15 4 0 1 -5 -7
-10 -13 -9 -13 -10 0 -10 8 0 33 5 54 11 28 8 35 13 25 19 -23 15 -2 23 29 12
30 -10 93 1 174 31 29 10 35 16 26 25 -9 9 -8 12 7 12 17 0 18 2 5 10 -13 9
-13 10 0 10 8 0 29 -4 46 -9 27 -8 31 -7 27 8 -4 17 6 24 70 50 17 7 25 16 21
23 -7 11 23 8 71 -7 11 -3 8 1 -7 10 -16 8 -28 18 -28 21 0 3 4 3 9 -1 14 -8
111 37 111 52 0 8 10 11 28 9 15 -2 19 -1 10 1 -31 8 -19 29 13 25 4 -1 28 19
55 43 27 25 54 45 61 45 7 0 13 5 13 11 0 5 5 7 10 4 6 -3 10 -2 10 4 0 5 8 8
18 6 10 -1 26 4 37 12 20 15 20 15 -2 10 -31 -7 -19 16 17 34 31 15 90 64 173
144 29 27 58 48 65 47 6 -2 12 4 12 13 0 8 5 15 10 15 6 0 14 8 17 18 4 9 17
27 30 39 13 12 23 27 23 33 0 5 7 10 15 10 9 0 15 9 15 25 0 18 5 25 18 25 11
0 24 11 30 25 6 14 16 25 22 25 5 0 10 10 10 22 0 12 16 35 40 55 22 19 40 40
40 46 0 6 12 21 28 33 27 22 27 23 7 38 -17 13 -18 15 -3 16 9 0 24 10 32 22
14 19 16 19 22 5 3 -9 4 -24 0 -32 -3 -8 -1 -15 4 -15 8 0 15 22 26 85 2 17
13 37 24 47 12 9 18 23 14 32 -8 21 6 20 21 -1 12 -16 12 -14 8 10 -5 27 20
96 41 109 6 4 14 0 18 -10 5 -9 5 -1 2 18 -3 24 -2 31 4 21 7 -10 9 3 7 40 -3
51 -2 52 9 25 l13 -29 5 48 c3 26 15 59 25 74 21 28 39 96 23 86 -6 -3 -7 1
-4 9 3 9 10 14 14 11 4 -3 11 10 15 28 7 35 25 153 27 177 1 8 5 18 10 21 9 6
11 29 19 179 3 55 4 50 6 -32 2 -60 -2 -130 -12 -180z m-1695 182 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-2603 -62
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m4120 0 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-4100 -160 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m13 -40 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14
14 9 14 -14z m70 -248 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m4 -44 c-3 -9 -10 -13 -15 -10 -5 3 -3 11 4 19 15 16 19 13 11
-9z m73 -178 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m13 -48 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m526 -51
c12 -10 20 -39 10 -39 -2 0 -21 7 -41 16 -27 11 -52 14 -86 9 -40 -6 -49 -4
-49 8 0 9 12 18 28 20 41 8 122 -1 138 -14z m130 10 c11 -19 -13 -34 -31 -19
-13 11 -13 15 -3 21 18 12 26 11 34 -2z m347 4 c10 -9 -13 -33 -32 -33 -9 0
-27 -15 -40 -33 -12 -17 -21 -27 -19 -22 23 66 34 85 52 88 12 2 24 5 27 6 3
0 9 -2 12 -6z m113 -4 c3 -6 1 -16 -4 -21 -13 -13 -45 8 -36 23 7 12 31 11 40
-2z m296 1 c35 -10 58 -34 58 -60 0 -6 -10 -2 -21 9 -22 20 -75 31 -138 28
-42 -2 -54 20 -14 26 52 8 78 7 115 -3z m258 -8 c0 -14 -8 -17 -33 -17 -68 3
-74 35 -6 35 31 0 39 -4 39 -18z m259 9 c16 -10 9 -13 -65 -25 -63 -10 -141
16 -81 27 42 8 132 7 146 -2z m171 -5 c0 -17 -49 -27 -73 -15 -27 15 -11 29
34 29 26 0 39 -4 39 -14z m260 -7 c0 -5 -11 -9 -25 -9 -26 0 -34 15 -12 23 16
5 37 -3 37 -14z m147 6 c8 -21 2 -24 -25 -14 -13 5 -20 13 -17 19 9 15 35 12
42 -5z m121 -3 c5 -17 -27 -27 -41 -13 -9 9 -8 14 3 21 18 11 32 8 38 -8z
m162 3 c10 -12 9 -16 -4 -21 -22 -8 -52 16 -34 27 18 12 24 11 38 -6z m240 -5
c13 -21 -5 -36 -25 -20 -11 9 -33 11 -74 7 -44 -5 -62 -3 -71 7 -15 19 5 23
93 22 48 -1 70 -5 77 -16z m221 9 c30 -15 13 -28 -30 -23 -21 2 -51 0 -66 -6
-37 -14 -46 -3 -16 21 25 21 81 25 112 8z m-2441 -15 c0 -11 -8 -14 -32 -12
-46 4 -49 28 -4 28 26 0 36 -4 36 -16z m-717 -73 c-6 -8 -20 13 -28 39 -6 23
-5 22 13 -5 11 -16 18 -32 15 -34z m3155 -11 c17 -11 32 -24 32 -30 0 -5 -1
-10 -3 -10 -11 0 -113 42 -121 50 -19 19 58 11 92 -10z m-2358 -19 c-13 -25
-45 -28 -54 -5 -9 22 0 30 17 15 11 -9 17 -8 27 4 17 21 24 12 10 -14z m1580
17 c0 -21 -110 -23 -117 -2 -2 7 17 11 57 10 33 0 60 -4 60 -8z m530 2 c0 -5
-15 -10 -32 -9 -28 0 -30 2 -13 9 28 12 45 12 45 0z m47 -36 c-9 -9 -28 6 -21
18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m-2917 6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m370 -4 c0 -27 -54 -35 -84 -13
-17 12 -15 14 19 15 21 1 40 4 43 7 10 10 22 5 22 -9z m1056 -31 c-3 -9 -11
-13 -16 -10 -8 5 -7 11 1 21 14 18 24 11 15 -11z m434 16 c0 -5 -7 -14 -15
-21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z m1221 -31 c36 0 40
-2 29 -15 -7 -8 -25 -15 -40 -15 -32 0 -100 26 -100 38 0 18 14 21 41 7 15 -8
47 -15 70 -15z m-2685 -27 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11
-17z m914 21 c0 -14 -70 -33 -92 -25 -22 9 -22 10 7 11 17 0 37 5 45 10 16 11
40 13 40 4z m1257 -14 c16 0 35 6 42 13 10 10 11 9 5 -6 -12 -33 -95 -35 -108
-2 -4 13 -2 14 14 5 10 -5 32 -10 47 -10z m313 6 c0 -26 -66 -34 -96 -11 -11
9 -13 13 -4 10 8 -2 31 0 50 4 50 13 50 13 50 -3z m-3010 -56 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m215 0 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m20 -30 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m72 -102 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m43 -28 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m160 -374 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m120 -91 c16 -19 0 -19 -26
0 -16 12 -16 14 -3 15 9 0 22 -7 29 -15z m190 -117 c0 -4 -12 -3 -26 3 -38 14
-45 19 -39 29 6 9 65 -20 65 -32z m46 -34 c19 -15 19 -15 -1 -6 -11 5 -27 12
-35 15 -13 5 -13 6 1 6 8 1 24 -6 35 -15z m79 6 c3 -5 -1 -10 -9 -10 -9 0 -16
5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m34 -46 c14 -13 -12 -13 -40 0 l-34
16 35 -6 c19 -4 37 -8 39 -10z m626 6 c8 -13 -5 -13 -25 0 -13 8 -13 10 2 10
9 0 20 -4 23 -10z m530 0 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12
15 0z m-482 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m154 -9
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m111 -1 c-16 -2 -40 -2
-55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m-838 -20 c-8 -3 -18 -10 -21 -16 -4
-5 -13 -6 -20 -2 -9 6 -8 10 7 16 10 4 26 8 34 8 13 -1 13 -1 0 -6z m77 -30
c-3 -3 -13 1 -21 10 -8 8 -12 17 -9 20 3 3 12 -1 20 -9 9 -8 13 -18 10 -21z
m-18 -9 c2 -2 2 -7 -1 -10 -4 -3 -19 2 -35 11 l-28 16 30 -6 c16 -4 32 -9 34
-11z m1416 7 c-16 -14 -55 -23 -55 -13 0 12 17 20 45 21 11 1 15 -3 10 -8z
m-1348 -25 c15 -8 44 -19 63 -25 l35 -10 -40 5 c-52 7 -103 23 -110 35 -9 14
20 11 52 -5z m128 -26 c-6 -10 7 -14 33 -11 6 1 12 -2 12 -7 0 -9 85 -28 190
-42 l55 -7 -62 -2 c-35 0 -65 4 -69 10 -3 6 -16 8 -27 5 -12 -3 -26 -2 -31 3
-6 4 -36 14 -68 21 -40 9 -55 17 -51 26 3 8 9 14 15 14 5 0 6 -4 3 -10z m118
-7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m880 -10 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-600 -60 c-18 -2 -48 -2 -65 0
-18 2 -4 4 32 4 36 0 50 -2 33 -4z m205 -7 c8 -9 -109 -5 -138 4 -15 5 6 6 54
4 43 -1 81 -5 84 -8z"/>
<path d="M2670 6170 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2298 5943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1611 4924 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2921 4536 c-10 -12 -11 -40 -4 -123 l8 -108 90 -2 c105 -2 139 8
155 48 17 41 9 155 -13 177 -25 24 -217 31 -236 8z"/>
<path d="M3657 4543 c-4 -3 -7 -113 -7 -244 l0 -239 23 -5 c13 -4 57 -4 98 -1
64 4 77 8 97 31 21 25 22 34 21 178 0 136 -3 157 -22 197 -12 25 -27 45 -32
45 -6 0 -26 10 -46 23 -35 21 -117 31 -132 15z"/>
<path d="M5641 3344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4800 2389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4730 2340 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4490 2230 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35 0z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
